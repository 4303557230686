:root {
    --header-height: 70px;
    --side-menu-height: 70px;
    @media (min-width: map-get($breakpoints, 'lg')) {
        --header-height: 100px;
        --side-menu-height: 0px;
    }
}

.header {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: var(--header-height);
    padding: 0 21px;
    z-index: 100;
    pointer-events: none;

    &--sticky {
        @extend .header;
        position: sticky;
        @include shadow('box-shadow');
    }

    & > * {
        pointer-events: auto;
    }


    &--sticky {
        @extend .header;
        position: sticky;
    }

    &__actions {
        margin-right: auto;
        font-size: 0;
        position: relative;
        display: flex;
        gap: 43px;

        &::after {
            content: "";
            display: inline-block;
            position: absolute;
            height: 20px;
            width: 1px;
            background-color: #FFFFFF;
            left: 50%;
            @include vertical-center;
        }

        .actions__item {
            height: 22px;
            width: 22px;
            background-repeat: no-repeat;
            background-position: center;
            
            &:hover {
                @include filter('sub');
            }

            &--menu {
                @extend .actions__item;
                @include background-picto('menu.svg');
            }

            &--search {
                @extend .actions__item;
                @include background-picto('search.svg');
            }
        }
    }

    &__locale {
        margin-left: auto;

        .locale__select {
            position: relative;
            &__label {
                @include link-reset;
                cursor: pointer;
                background: none;
                border: none;
                color: #FFFFFF;
                font-size: 16px;
            }

            &__choices {
                display: flex;
                .lang {
                    @include link-reset;
                    position: relative;
                    cursor: pointer;
                    background: none;
                    border: none;
                    color: #FFFFFF;
                    font-size: 16px;

                    &:not(:last-child) {
                        margin-right: 16px;
                        opacity: 1;
                        transition: opacity 0.2s;
                        &::after {
                            content: '|';
                            position: absolute;
                            top: 0;
                            right: -10px;
                            pointer-events: none;
                            color: rgba(255, 255, 255, 0.6);
                        }
                    }
                    &:hover {
                        color: $sub-color;
                    }
                }
            }
        }
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        padding: 0 25px;

        &__actions {
            gap: 50px;

            &::after {
                height: 50px;
            }
            
            .actions__item {
                height: 38px;
                width: 38px;

                &--menu {
                    position: relative;
                    top: 0;
                    transition: top 0.3s;

                    &:hover {
                        top: -10px;

                        &::after {
                            content: "MENU";
                            display: block;
                            position: absolute;
                            bottom: -18px;
                            left: 50%;
                            transform: translateX(-50%);
                            padding-top: 10px;
                            font-size: 13px;
                            font-weight: 700;
                        }
                    }
                }
            }
        }

        &__locale .locale__select {
            font-weight: 300;
            font-size: 18px;

            &__choices:not(:hover) .lang:not(:last-child) {
                opacity: 0;
            }
        }
    }
}

.header-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    height: var(--header-height);
    background-color: add-opacity($purple-dark-color, 96);

    &__logo img {
        width: 85px;
    }
    
    @media (min-width: map-get($breakpoints, 'lg')) {
        &__logo img {
            width: 145px;
        }
    }
}

.header-menu {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    bottom: 0;
    width: 100%;
    overflow-y: auto;
    background-color: #FFFFFF;

    &::before {
        content: "";
        display: block;
        position: fixed;
        height: 425px;
        width: 220px;
        right: -75px;
        bottom: -85px;
        @include background-picto('pappus_2.svg');
        background-position: bottom right;
        transform: rotate(-20deg);
        @include filter('main');
    }

    &.opened {
        display: block;
    }

    .header {
        position: relative;

        &::before {
            content: none;
        }

        &__actions {
            &::after {
                background-color: $main-color;
            }
    
            .actions__item {
                &--close {
                    @extend .actions__item;
                    @include background-picto('close_bold.svg');
                }
            }
        }

        &__locale .locale__select {
            color: $main-color;
        }
    }

    &__content {
        margin: -5px 20px 0;
        padding-bottom: 90px;
    }

    @mixin section-bottom-border {
        &::after {
            content: "";
            display: block;
            height: 2px;
            width: 31px;
            background-color: $sub-color;
            margin-top: 30px;
        }
    }

    &__section:not(:has(~ &__section)) {
        @include section-bottom-border;
    }

    &__section {
        margin-bottom: 20px;

        .section__title {
            @include font-size(40);
            font-weight: 900;
            margin-top: 0;
            margin-bottom: 8px;
        }

        .section__links {
            @include list-reset;

            .links__item {
                margin-bottom: 0.3rem;

                a {
                    @include link-reset;
                    font-size: 0.83rem;
                    cursor: pointer;

                    &::after {
                        content: "";
                        display: inline-block;
                        width: 0.5em;
                        height: 0.5em;
                        @include background-picto('a_right.svg');
                        margin-left: 9px;
                        vertical-align: middle;
                    }

                    &:hover {
                        color: $sub-color;

                        &::after {
                            @include filter('sub');
                        }
                    }
                }
            }
        }
    }

    &__footer {
        margin: 26px 0 30px;

        .footer__item {
            margin-top: 16px;

            &:first-child {
                margin-top: 0;
            }

            a {
                @include link-reset;
                @include font-size(20);
                font-weight: 900;
            }

            &--socials {
                margin-top: 38px;
                font-size: 0;

                .socials__item {
                    position: relative;
                    display: inline-block;
                    margin: 0 18px;
                    
                    &::after {
                        position: absolute;
                        content: "";
                        display: inline-block;
                        width: 1px;
                        height: 120%;
                        right: -20px;
                        top: -10%;
                        background-color: $main-color;
                        pointer-events: none;
                    }

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;

                        &::after {
                            content: none;
                        }
                    }

                    .icon {
                        display: inline-block;
                        width: 25px;
                        height: 16px;
                        background-repeat: no-repeat;
                        background-position: center;
                    }

                    &:hover .icon {
                        @include filter('sub');
                    }

                    &--contact {
                        @extend .socials__item;
                        .icon {
                            @include background-picto('envelope.svg');
                        }
                    }

                    &--linkedin {
                        @extend .socials__item;
                        .icon {
                            background-image: url('../../images/logo/linkedin.svg');
                        }
                    }

                    &--x {
                        @extend .socials__item;
                        .icon {
                            background-image: url('../../images/logo/x.svg');
                        }
                    }
                }
            }
        }
    }

    @media (min-width: map-get($breakpoints, 'lg')) {

        max-width: 900px;
        overflow: hidden;

        &::before {
            position: absolute;
            height: 505px;
            width: 260px;
            right: 60px;
            bottom: -230px;
            background-position: right bottom;
            transform: none;
        }

        .header {
            &__actions .actions__item--close {
                position: relative;
                top: -10px;
                @include background-picto('close.svg');
                
                &::after {
                    content: "MENU";
                    display: block;
                    position: absolute;
                    bottom: -18px;
                    left: 50%;
                    transform: translateX(-50%);
                    padding-top: 10px;
                    font-size: 13px;
                    font-weight: 700;
                }
            }
    
            &__locale {
                display: none;
            }
        }

        &__content {
            display: grid;
            gap: 2.6rem 0;
            grid-template-columns: 1fr 1fr;
            padding: 0 0 0 4.7rem;
            margin: 2.6rem 0 0 0;
        }

        &__section {
            margin-bottom: 0;
            @include section-bottom-border;

            &::after {
                margin-top: 1.25rem;
            }

            .section__title {
                font-size: 2.08rem;
                margin-bottom: 0.8rem;
            }
        }

        &__footer {
            margin-top: 5px;

            .footer__item {
                a {
                    font-size: 1.15rem;
                }

                &--socials {
                    display: none;
                }
            }
        }

        // --- Height optimisation
        @media (max-height: map-get($breakpoints-height, 'lg')) {
            &__content {
                margin: 0;
            }
        }
        @media (max-height: map-get($breakpoints-height, 'md')) {
            &__content {
                margin: -25px 0 0 0;
                gap: 1.6rem 0;
            }
        }
    }
}

.side-menu {
    position: fixed;
    z-index: 1000;
    right: 0;
    left: 0;
    bottom: 0;
    height: 70px;
    background-color: #FFFFFF;
    box-shadow: 0px -3px 6px #00000029;

    &__item {
        text-align: center;

        a {
            display: inline-block;
            height: 45px;
            width: 110px;
            margin: 15px 0;
            padding-left: 40px;
            background-repeat: no-repeat;
            background-position: center;
            @include filter('main');

            &:hover {
                @include filter('sub');
            }
        }

        &--contact {
            @extend .side-menu__item;
            a {
                @include link-reset;
                @include background-picto('envelope.svg');
                background-position: left center;
                background-size: 30px;
                font-size: 20px;
                line-height: 40px;
                font-weight: 900;
                color: #000000;
            }
        }

        &--linkedin {
            @extend .side-menu__item;
            a {
                background-image: url('../../images/logo/linkedin.svg');
                background-size: 22px;
            }
        }

        &--x {
            @extend .side-menu__item;
            a {
                background-image: url('../../images/logo/x.svg');
                background-size: 22px;
            }
        }
    }
    
    @media (min-width: map-get($breakpoints, 'lg')) {
        display: block;
        left: auto;
        bottom: 50%;
        transform: translateY(50%);
        width: 65px;
        height: auto;
        border-radius: 5px 0 0 5px;
        font-size: 0;
        @include shadow('box-shadow');

        &__item {
            border-bottom: 1px solid $main-color;
            width: 40px;
            margin: auto;

            &:last-child {
                border-bottom: none;
            }

            a {
                width: 100%;
                margin: 8px 0;
            }

            &--contact a {
                position: relative;
                padding-left: 0;
                background-position: center center;
                background-size: 35px;
                transition: transform 0.3s;
                font-size: 0;
                line-height: normal;

                &::after {
                    content: "CONTACT";
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%, -15px) scale(1.2);
                    color: #000000;
                    font-size: 10px;
                    font-weight: 900;
                    transition: all 0.3s;
                    opacity: 0;
                }

                &:hover {
                    transform: translateY(-10px);

                    &::after {
                        opacity: 1;
                        transform: translate(-50%, 6px) scale(1);
                    }
                }
            }
        }

        // @media (min-height: 801px) {
        //     width: 80px;

        //     &__item {
        //         width: 55px;

        //         a {
        //             margin: 15px 0;
        //         }

        //         &--contact a {
        //             background-size: 45px;
    
        //             &::after {
        //                 font-size: 11px;
        //             }
    
        //             &:hover {
        //                 transform: translateY(-10px);
    
        //                 &::after {
        //                     transform: translate(-50%, 10px) scale(1);
        //                 }
        //             }
        //         }
    
        //         &--linkedin a {
        //             background-size: 28px;
        //         }
        
        //         &--x a {
        //             background-size: 28px;
        //         }
        //     }
        // }
    }
}

.search-window {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $main-color;
    background-image: url('../../images/geometric_bg.webp');
    background-size: cover;
    background-position: bottom -180px center;
    background-repeat: no-repeat;
    justify-content: center;
    color: #FFFFFF;

    &.opened {
        display: flex;
    }

    &__close {
        position: absolute;
        top: 20px;
        left: 20px;
        height: 26px;
        width: 26px;
        @include background-picto('close.svg');
        @include filter('white');

        &:hover {
            @include filter('sub');
        }
    }

    .content {
        padding: 150px 20px 0;
        max-width: 100%;
    }

    .search-form {
        margin-bottom: 65px;
    }
    
    @media (min-width: map-get($breakpoints, 'lg')) {
        background-position: bottom -375px left;
        align-items: center;

        &::after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            pointer-events: none;
            display: inline-block;
            height: 610px;
            width: 440px;
            @include background-picto('pappus_2.svg');
            background-position: bottom -160px center;
            @include filter('white');
        }

        .content {
            padding: 25px 30px;
            max-width: 725px;
        }

        &__close {
            top: 2rem;
            left: 2rem;
            height: 2rem;
            width: 2rem;
        }

        .search-form {
            margin-bottom: 3rem;
        }
    }
}