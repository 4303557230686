// --------- MAIN CSS

// Do not write CSS in this file
// the only purpose is to import all css files
// and declare variables

// ------ Lib
@import "~normalize.css/normalize.css";

// ------ Iperia.eu

// Styles
@import "_vars.scss";
@import "modules/_colors.scss";
@import "modules/_fonts.scss";
@import "modules/_mixins.scss";
@import "modules/_functions.scss";
@import "modules/_animations.scss";
@import "modules/utilities.scss";
@import "modules/components.scss";
@import "modules/shared_blocks.scss";

@import "layouts/base.scss";
@import "layouts/_header.scss";
@import "layouts/_footer.scss";
@import "layouts/_popup.scss";
@import "layouts/server_error.scss";
@import "themes/global.scss";
@import "themes/form.scss";