// --------- THEME GLOBAL

@each $value, $color in $colors {
    .apply-#{$value} {
        &-color {
            color: $color;
        }
        &-bg-color {
            background-color: $color;
        }
        &-border-color {
            border-color: $color;
        }
        &-hover-color:hover {
            color: $color;
        }
        &-hover-bg-color:hover {
            background-color: $color;
        }
    }
    svg.apply-#{$value}-color {
        fill: $color;
    }
}

// --- For background svg where you cannot override fill property
@each $value, $filter in $colors-filter {
    .apply-#{$value} {
        &-filter {
            filter: $filter;
        }
    }
}