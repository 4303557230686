@-webkit-keyframes appear {
    from {opacity:0} 
    to {top:0; opacity:1}
}

@keyframes appear {
    from {opacity:0}
    to {top:0; opacity:1}
}

// Popups modal
.popup-modal {
    display: none;
    position: fixed;
    z-index: 1001;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: #26123dcc;
    
    &.active {
        display: flex;
    }

    .modal-content {
        position: relative;
        animation: appear 0.4s;
        background-color: #fefefe;
        margin: auto;
        overflow: auto;
        border-radius: 10px;
        padding: 0;
        width: calc(100% - 40px);
        max-height: calc(100% - 40px);

        &__img {
            width: 100%;
            font-size: 0;

            img {
                width: 100%;
                max-height: 50vh;
                aspect-ratio: 1;
                object-fit: cover;
                object-position: center;
            }
        }

        &__right {
            padding: 45px 25px;
        }

        &__title {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 15px;
        }

        &__content ul {
            @extend .list--dots;
        }

        &__cta {
            display: flex;
            flex-direction: column;
            margin: 40px 25px 0;
            gap: 10px;

            a {
                flex: 1;
                font-size: 20px;
                font-weight: 900;
            }
        }

        .close {
            position: absolute;
            top: 15px;
            right: 15px;
            width: 20px;
            height: 20px;
            background: url('../../images/picto/close.svg');
            background-repeat: no-repeat;
            @include filter('sub');
            border: none;
            cursor: pointer;
        }

        &--full-img {
            @extend .modal-content;

            .modal-content__img {
                width: 100%;

                img {
                    max-width: 100%;
                    max-height: 100%;
                    aspect-ratio: auto;
                }
            }

            .close {
                filter: none;
            }
        }

        figure {
            margin: 0;
        }
    }
    
    @media (min-width: map-get($breakpoints, 'lg')) {
        .modal-content {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            max-width: 1060px;
    
            &__img {
                width: 40%;
    
                img {
                    max-height: unset;
                    height: 100%;
                }
            }
    
            &__right {
                flex: 1;
                padding: 45px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
    
            &__title {
                margin-bottom: 20px;
            }
    
            &__cta {
                flex-direction: row;
                gap: 20px;
                margin: 40px 0 0;

                // If only one button
                a:first-child:last-child {
                    margin: 0 25px;
                }
            }
        }
    }
}