// --------- UTILITIES

// Hidden but accessible
.visuallyhidden {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
    clip-path: inset(0px 0px 99.9% 99.9%);
    overflow: hidden;
    height: 1px;
    width: 1px;
    padding: 0;
    border: 0;
}

// Link
.link-reset {
    @include link-reset;
}

// Fonts
@each $size, $height in $font-sizes {
    .font-size--#{$size} {
        font-size: #{$size}px;
    }
}
@each $size, $height in $font-sizes {
    .line-height--#{$height} {
        line-height: #{$height}px;
    }
}
@each $breakpoint, $value in $breakpoints {
    @media (min-width: $value) {
        @each $size, $height in $font-sizes {
            .font-size-#{$breakpoint}--#{$size} {
                font-size: #{$size}px;
            }
        }
        @each $size, $height in $font-sizes {
            .line-height-#{$breakpoint}--#{$height} {
                line-height: #{$height}px;
            }
        }
    }
}
@each $color, $value in $colors {
    .color--#{$color} {
        color: #{$value};
    }
}

.font-weight {
    &--bold {
        font-weight: bold;
    }
    &--black {
        font-weight: 900;
    }
}
.font-style {
    &--italic {
        font-style: italic;
    }
}
.text-transform {
    &--uppercase {
        text-transform: uppercase;
    }
    &--initial {
        text-transform: initial;
    }
}
.text-decoration {
    &--underline {
        text-decoration: underline;
    }
}
.letter-spacing--large {
    letter-spacing: 10px;
}

// Display
.display {
    &--none {
        display: none;
    }
    &--block {
        display: block;
    }
    &--inline-block {
        display: inline-block;
    }
    &--flex {
        display: flex;
    }
    &--inline {
        display: inline;
    }
    &--grid {
        display: grid;
    }
}
@each $breakpoint, $value in $breakpoints {
    @media (min-width: $value) {
        .display-#{$breakpoint}--none {
            display: none;
        }
        .display-#{$breakpoint}--block {
            display: block;
        }
        .display-#{$breakpoint}--inline-block {
            display: inline-block;
        }
        .display-#{$breakpoint}--flex {
            display: flex;
        }
        .display-#{$breakpoint}--inline {
            display: inline;
        }
        .display-#{$breakpoint}--grid {
            display: grid;
        }
    }
}
.position {
    &--relative {
        position: relative;
    }
    &--absolute {
        position: absolute;
    }
}

// Overflow
.overflow {
    &--hidden {
        overflow: hidden;
    }

    &-x--hidden {
        overflow-x: hidden;
    }

    &-y--hidden {
        overflow-y: hidden;
    }
}

// Alignment
.vertical-center {
    @include vertical-center;
}
.text-align {
    &--left {
        text-align: left;
    }
    &--right {
        text-align: right;
    }
    &--center {
        text-align: center;
    }
}
.vertical-align {
    &--middle {
        vertical-align: middle;
    }
    &--top {
        vertical-align: top;
    }
    &--bottom {
        vertical-align: bottom;
    }
}

// Shadow
.box-shadow {
    @include shadow();
}
.text-shadow {
    @include shadow('text-shadow');
}

// Flex
.flex-break {
    flex-basis: 100%;
}

// Lazy Load
.lazy-img,
.lazy-img::before,
.lazy-img::after {
    background-image: none !important;
}