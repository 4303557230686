// --------- FONTS

@font-face {
    font-family: 'Segoe UI';
    font-weight: 200;
    font-style: normal;
    src: url('../../fonts/SegoeUI/light.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Segoe UI';
    font-weight: 200;
    font-style: italic;
    src: url('../../fonts/SegoeUI/lightitalic.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Segoe UI';
    font-weight: 300;
    font-style: normal;
    src: url('../../fonts/SegoeUI/semilight.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Segoe UI';
    font-weight: 300;
    font-style: italic;
    src: url('../../fonts/SegoeUI/semilightitalic.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Segoe UI';
    font-weight: 400;
    font-style: normal;
    src: url('../../fonts/SegoeUI/regular.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Segoe UI';
    font-weight: 400;
    font-style: italic;
    src: url('../../fonts/SegoeUI/italic.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Segoe UI';
    font-weight: 600;
    font-style: normal;
    src: url('../../fonts/SegoeUI/semibold.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Segoe UI';
    font-weight: 600;
    font-style: italic;
    src: url('../../fonts/SegoeUI/semibolditalic.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Segoe UI';
    font-weight: 700;
    font-style: normal;
    src: url('../../fonts/SegoeUI/bold.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Segoe UI';
    font-weight: 700;
    font-style: italic;
    src: url('../../fonts/SegoeUI/bolditalic.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Segoe UI';
    font-weight: 800;
    font-style: normal;
    src: url('../../fonts/SegoeUI/black.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Segoe UI';
    font-weight: 800;
    font-style: italic;
    src: url('../../fonts/SegoeUI/blackitalic.woff') format('woff');
    font-display: swap;
}

@mixin font-segoeui {
    font-family: 'Segoe UI', 'Frutiger', 'Dejavu Sans', 'Helvetica Neue', 'Arial', 'sans-serif';
}