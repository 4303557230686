// --------- Server error CSS

#server-error {
    .error {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: calc(-1 * var(--header-height));
        padding: var(--header-height) 20px 0;
        height: 100vh;

        background-image: url('../../images/newsletter_bg.webp');
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: cover;

        &__title {
            display: inline-block;
            height: 95px;
            width: auto;
            max-width: 100%;
            aspect-ratio: 3;
            background-image: url('../../images/oups.svg');
            background-repeat: no-repeat;
            font-size: 0;
            margin-bottom: 10px;
        }
        &__message {
            @include font-size(20);
            text-align: center;
            color: #ffffff;
            margin-bottom: 35px;
            max-width: 270px;
        }

        &__btn {
            width: 200px;
            text-align: center;
        }
    }

    @media (min-width: map-get($breakpoints, "lg")) {
        .error {
            background-position: bottom 40% center;

            &__title {
                height: 200px;
            }
            &__message {
                @include font-size(25);
                max-width: 510px;
                margin-bottom: 70px;
            }
            &__btn {
                @include font-size(28);
                width: 280px;
                height: 85px;
                line-height: 32px;
            }
        }
    }
}