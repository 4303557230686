// Articles

.articles-container {
    display: grid;
    width: calc(100% + 20px);
    padding-right: 20px;
    row-gap: 95px;
    grid-template-columns: repeat(1, 1fr);

    &__no-result {
        text-align: center;
        font-weight: bold;
        // @include font-size(20);
        grid-column: 1/-1;
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        grid-template-columns: repeat(3, 1fr);
        gap: 85px 30px;
        width: 100%;
        padding-right: 0;
        &__no-result {
            @include font-size(24);
        }
    }
}

.article-preview {
    @include link-reset;
    display: flex;
    flex-direction: column;
    height: 100%;

    &:hover .article-preview__image {
        @include hover-animation-active-state
    }
    
    &__image {
        overflow: hidden;
        width: 100%;
        aspect-ratio: 1.44;
        margin-bottom: 20px;
        
        @include hover-animation-initial-state;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }

    &__content {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    &__category {
        @include category-badge;
    }

    &__title {
        font-weight: 900;
        font-size: 22px;
        margin-bottom: 20px;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
    }

    &__text {
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        margin-bottom: 20px;
    }

    &__metas {
        display: flex;
        justify-content: space-between;
        padding-top: 15px;
        border-top: 2px solid #000000;
        margin-top: auto;

        .article-preview__readtime {
            font-weight: 700;
            color: $sub-color;

            &::before {
                content: "";
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-right: 10px;
                margin-bottom: -3px;
                @include background-picto('stopwatch.svg');
                @include filter('sub');
            }
        }

        .article-preview__state {
            &--ongoing, &--completed {
                font-weight: 700;
                color: $sub-color;

                &::before {
                    content: "";
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                    margin-bottom: -3px;
                    @include filter('sub');
                }
            }

            &--ongoing::before {
                @include background-picto('construction_plot.svg');
            }

            &--completed::before {
                @include background-picto('check_circle.svg');
            }
         }
    }

    &--highlight {
        @extend .article-preview;
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        &__image {
            margin-bottom: 0.8rem;
        }
        &__title {
            font-size: 1.1rem;
            margin-bottom: 1rem;
        }
        &__metas {
            padding-top: 10px;
            .article-preview__readtime::before {
                width: 25px;
                height: 25px;
            }
        }

        &--highlight {
            grid-column: 1 / -1;
            display: flex;
            flex-direction: row;
            height: 500px;
            overflow: hidden;

            .article-preview__image {
                flex: 0 0 65%;
                margin-bottom: 0;
            }

            .article-preview__content {
                padding-left: 40px;
                flex: 0 0 35%;
            }

            .article-preview__text {
                margin-bottom: 0;
            }
        }
    }

    // @media (min-width: map-get($breakpoints, 'xxxl')) {
    //     &--highlight {
    //         .article-preview__content {
    //             padding-left: 50px;
    //         }
    //     }
    // }
    // @media (min-width: map-get($breakpoints, 'xxxl')) {
    //     &--highlight {
    //         height: 670px;
    //     }
    // }
}

// NEWS CAROUSEL
.other-news {
    &__title {
        margin-top: 45px;
        margin-bottom: 45px;
    }

    &__carousel {
        position: relative;
        padding-bottom: 50px;
        width: calc(100% + 20px);

        .swiper-wrapper {
            display: flex;
        }

        .swiper-slide {
            height: auto;
        }

        .swiper-button-prev,
        .swiper-button-next {
            top: 70px;
        }
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        &__title {
            margin-top: 2.4rem;
            margin-bottom: 4.5rem;
        }

        &__carousel {
            margin-bottom: 4rem;
            width: 100%;
        }
        .swiper-button-prev,
        .swiper-button-next {
            top: 130px;
            transform: translateY(-50%);
        }
    }

    // @media (min-width: map-get($breakpoints, 'xxxl')) {

    //     &__carousel {
    //         margin-bottom: 110px;
    //     }
    //     &__title {
    //         margin-bottom: 105px;
    //     }
    // }
}
.container--lg .other-news__carousel {
    @media (min-width: map-get($breakpoints, 'lg'))
        {
            .carousel__controller {
                .swiper-button-prev {
                    left: -5px;
                }
                .swiper-button-next {
                    right: -5px;
                }
            }
        }

        @media (min-width: 1480px) {
            .carousel__controller {
                .swiper-button-prev {
                    left: -45px;
                }
                .swiper-button-next {
                    right: -45px;
                }
            }
        }
}

// NEWSLETTER / LE SAVIEZ VOUS
.newsletter,
.did-you-know {
    background-image: url('../../images/newsletter_bg.webp');
    background-size: cover;
    background-position: center;
}

.newsletter {
    &__content {
        padding: 40px 10px 45px;
        gap: 50px;

        .newsletter__title {
            display: inline-block;
            width: calc(100% - 25px);
            aspect-ratio: 2.1;
            background-image: url('../../images/newsletter_title.svg');
            background-repeat: no-repeat;
            font-size: 0;
            margin-bottom: 30px;
            max-width: 450px;
        }
        .newsletter__description {
            font-size: 20px;
            color: #FFFFFF;
            margin-bottom: 35px;
        }
        .newsletter__button-container {
            text-align: center;
        }
        .newsletter__button {
            width: 200px;
        }
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        &__content {
            padding: 2.8rem 0 4.2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 3rem;
            margin: auto;
            max-width: 82%;

            .newsletter__title {
                margin-bottom: 0;
                flex: 1 0 17rem;
            }
            .newsletter__description {
                font-size: 1.25rem;
                line-height: 1.11em;
                margin-bottom: 0;
            }
            .newsletter__button {
                margin-left: 20px;
            }
        }
    }
}

.did-you-know {
    &__content {
        padding: 40px 30px 65px;

        .did-you-know__title {
            background-image: url('../../images/saviez_vous.svg');
            background-size: contain;
            background-repeat: no-repeat;
            aspect-ratio: 1.6;
            font-size: 0;
            margin: 0 auto 30px;
            width: 100%;
        }
        .did-you-know__description {
            font-size: 20px;
            color: #FFFFFF;

            :is(p, ul, .btn):has(+ :is(p, ul, .btn)) {
                margin-bottom: 40px;
            }

            ul {
                margin-bottom: 0;
                li {
                    &::marker {
                        color: $sub-color;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                &.--with-margin li {
                    margin-bottom: 20px;
                }
            }

            blockquote {
                
                margin: 0 0 25px;
                font-size: 1.12rem;
                line-height: 1.24em;
                
                &::before,
                &::after {
                    content: "";
                    display: block;
                    height: 1.7rem;
                    @include filter('sub');
                }
          
                &::before {
                    @include background-picto('quote_left.svg');
                    background-position: left;
                    margin-bottom: 0.2rem;
                }
          
                &::after {
                    @include background-picto('quote_right.svg');
                    background-position: right;
                    margin-top: 0.2rem;
                }
            }
        }
        .did-you-know__button-container {
            text-align: center;
            padding-top: 45px;
        }
        .did-you-know__button {
            width: 200px;
        }
    }
    
    @media (min-width: map-get($breakpoints, 'lg')) {
        &__content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 3rem;
            margin: auto;
            max-width: 82%;
            padding: 2.8rem 0 4.2rem;
            .did-you-know__title {
                margin-bottom: 0;
                flex: 1 0 20rem;
            }
            .did-you-know__description {
                flex: 1 1 auto;
                margin-bottom: 0;

                :is(p, ul, .btn):has(+ :is(p, ul, .btn)) {
                    margin-bottom: 1rem;
                }

                .btn {
                    margin-top: 0.5rem;
                }

                blockquote {
                    margin-bottom: 2.3rem;
                    font-size: 1.25rem;
                    line-height: 1.11em;
                }
            }
            .did-you-know__button {
                width: 200px;
            }
        }
    }
    
    // @media (min-width: map-get($breakpoints, 'xxxl')) {
    //     &__content {
    //         gap: 5vw;
    //         .did-you-know__button {
    //             width: 284px;
    //         }
    //     }
    // }
}
:lang(en) .did-you-know__title {
    background-image: url('../../images/did_you_know.svg');
}

// BREADCRUMBS
.breadcrumbs {
    padding: 10px 0 95px 0;

    &__item {
        display: none;
    }

    a:last-of-type {
        display: inline-block;
        color: transparent;
        white-space: nowrap;
        //font-size: 16px;
        &::before {
            content: '< Retour';
            color: #000000;
            text-decoration: none;
            font-weight: normal;
            @include filter('main');
        }
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        padding: 0.8rem 0 4rem 0;
        &__item {
            @include link-reset;
            //font-size: 20px;
            position: relative;
            padding-right: 25px;
            display: inline;
            &:hover {
                text-decoration: underline;
            }

            &--unlinked:hover {
                text-decoration: none;
            }

            &::after {
                content: '/';
                text-decoration: none;
                position: absolute;
                margin-left: 10px;
                top: 0;
            }
            &:last-child {
                font-weight: bold;
                &:hover {
                    text-decoration: none;
                }
                &::after {
                    content: none;
                }
            }
        }
        a:last-of-type {
            //font-size: 22px;
            display: inline;
            color: inherit;
            &::before {
                content: none;
            }
        }
    }
    // @media (min-width: map-get($breakpoints, 'xxxl')) {
    //     &__item {
    //         font-size: 25px;
    //     }
    //     a:last-of-type {
    //         font-size: 25px;
    //     }
    // }
}
@media (max-width: map-get($breakpoints, 'lg')) {
    :lang(en) .breadcrumbs a:last-of-type::before {
        content: '< Back';
    }
}

// GUARANTEE HEADER
.page-header {
    overflow: hidden;
    background-color: $background-color;

    &__left {
        padding-top: 45px;
        padding-bottom: 50px;
        padding-right: 20px;
        padding-left: 20px;
    }

    &__category {
        display: block;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 100;
        margin-bottom: 10px;
    }

    &::after {
        content: "";
        display: block;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        height: 330px;
        align-self: stretch;
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        display: flex;
        align-items: center;
        height: 650px;

        &__title {
            -webkit-hyphens: none;
            -moz-hyphens:    none;
            -ms-hyphens:     none;
            hyphens:         none;
        }
        &__category {
            font-size: 1.6rem;
            margin-bottom: 0.8rem;
        }
        .page-header__left {
            padding: 0 2.3rem;
            flex: 0 0 38%;
        }
        &::after {
            height: 100%;
            flex: 1 1 auto;
        }

        &.equal-width .page-header__left {
            flex: 0 0 50%;
        }

        @media (max-height: map-get($breakpoints-height, 'md')) {
            height: 400px;
        }
        @media (max-height: map-get($breakpoints-height, 'lg')) {
            height: 550px;
        }
    }

    // @media (min-width: map-get($breakpoints, 'xxxl')) {
    //     &__category {
    //         font-size: 39px;
    //     }

    //     .page-header__left {
    //         padding-left: 6.7vw;
    //     }
    // }
}

// TESTIMONIES
.testimonies {

    &__carousel {

        .testimonies__item {
            background-color: $background-color;
            padding: 50px 20px 70px 20px;
            margin-top: 40px;
            height: auto;

            .testimony {
                &__identity {
                    text-align: center;
                }
    
                &__name {
                    font-size: 25px;
                    line-height: 30px;
                    font-weight: 900;
                    text-transform: uppercase;
                    margin-bottom: 10px;
                }
    
                &__tags {
                    span {
                        display: block;
                        &:first-child {
                            text-align: center;
                            font-weight: bold;
                            padding: 0 20px;
                            margin-bottom: 90px;
                        }
                    }
                }
    
                &__value {
                    position: relative;
                    margin-bottom: 60px;
                    font-size: 18px;
                    line-height: 25px;
                    &::before, &::after {
                        content: '';
                        position: absolute;
                        width: 2.3rem;
                        height: 1.7rem;
                        @include filter('sub');
                    }
    
                    &::before {
                        top: -2.5rem;
                        left: 0;
                        @include background-picto('quote_left.svg');
                        @include filter('sub');
                    }
                    &::after {
                        bottom: -2.5rem;
                        right: 0;
                        @include background-picto('quote_right.svg');
                        @include filter('sub');
    
                    }
                }
    
                &__footnote {
                    margin-top: 100px;
                    font-size: 16px;
                }
            }
        }

        .swiper-wrapper {
            align-items: stretch;
        }

        &.--with-image {
            .testimony__image {
                width: 210px;
                height: 210px;
                object-fit: cover;
                border-radius: 50%;
                margin-bottom: 20px;
                margin-top: -80px;
            }
        }
    }
    // .carousel__controller > * {
    //     transform: translateY(60px);
    // }

    @media (min-width: map-get($breakpoints, 'lg')) {

        &__carousel {

            position: relative;

            .testimonies__item {
                padding: 25px 3rem 1.8rem 3rem; // padding-top in px required
                margin-top: 70px;
    
                .testimony {
                    display: flex;
                    align-items: center;
                    column-gap: 70px;
                    flex-wrap: wrap;
    
                    &__tags {
                        span {
                            &:first-child {
                                padding: 0;
                                margin-bottom: 0;
                            }
                        }
                    }
                    &__identity {
                        flex: 0 0 300px;
                    }
                    &__content {
                        flex: 1 1 0%;
                    }
                    &__value {
                        margin-top: 60px;
                        padding-right: 1rem;
                        font-size: 1.25rem;
                        line-height: 1.11em;
                    }
                    &__name {
                        font-size: 1.67rem;
                    }
                    &__footnote {
                        margin-top: 0.6rem;
                        flex: 0 0 100%;
                        font-size: 0.74rem;
                        line-height: 1em;
                    }
                }
            }

            &.--with-image {

                .testimony {
                    align-items: flex-start;
                }
                .testimony__image {
                    width: 260px;
                    height: 260px;
                    margin-top: -80px;
                }
            }
        }
    }

    // @media (min-width: map-get($breakpoints, 'xxl')) {

    //     &__item {
    //         padding: 40px 60px 70px 60px;
    //         .testimony {
    //             &__content {
    //                 flex-basis: 540px;
    //             }
    //         }

    //         &--with-image .testimony {
    //             &__identity {
    //                 padding-top: 230px;
    //             }
    //             &__image {
    //                 width: 285px;
    //                 height: 285px;
    //             }
    //         }
    //     }
    // }

    // @media (min-width: map-get($breakpoints, 'xxxl')) {

    //     &__item {
    //         .testimony {
    //             &__content {
    //                 flex-basis: 620px;
    //             }
    //             &__value {
    //                 margin-top: 60px;
    //             }
    //         }

    //         &--with-image .testimony {
    //             &__image {
    //                 width: 313px;
    //                 height: 313px;
    //             }
    //         }
    //     }
    // }
}

.show-more {
    @include link-reset;
    @include font-size(18);
    cursor: pointer;
    color: $main-color;
    font-weight: bold;

    &::after {
        content: "";
        display: inline-block;
        width: 0.7em;
        height: 0.7em;
        @include background-picto('a_right_bold.svg');
        @include filter('main');
        margin-left: 9px;
        vertical-align: middle;
    }

    &:hover {
        color: $sub-color;

        &::after {
            @include filter('sub');
        }
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        @include font-size(20);
    }
}

// POPOVERS 
.popover {
    position: relative;
    
    &__message {
        position: absolute;
        text-align: center;
        background-color: $purple-dark-color;
        color: #ffffff;
        padding: 10px;
        margin: 0 0 15px 0;
        bottom: 100%;
        right: 50%;
        transform: translateX(50%);
        width: max-content;
        opacity: 0;
        transition: opacity 0.2s ease-in;

        &.active {
            opacity: 1;
        }

        &::after {
            position: absolute;
            z-index: -1;
            content: "";
            right: calc(50% - 10px);
            bottom: -10px;
            border-style: solid;
            border-width: 10px 10px 0px 10px;
            border-color: $purple-dark-color transparent transparent transparent;
        }
    }
}

// SEARCH
.search-form .search-form {
    &__field {
        border-bottom: 2px solid #FFFFFF;
        font-weight: 900;
        font-size: 24px;
        line-height: 30px;
        text-transform: uppercase;
        color: $sub-color;
        padding: 5px 45px 5px 40px;

        &::placeholder {
            color: $sub-color;
        }
    }

    &__search {
        content: '';
        display: inline-block;
        @include filter('white');
        @include background-picto('search.svg');
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 24px;
        width: 24px;

        &:hover {
            @include filter('sub');
        }
    }

    &__clear {
        display: inline-block;
        @include filter('white');
        @include background-picto('close.svg');
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        height: 17px;
        width: 17px;

        &:hover {
            @include filter('sub');
            cursor: pointer;
        }
    }

    &__suggestions {
        margin-top: 6px;
        background-color: #FFFFFF;
        color: #000000;
        position: absolute;
        width: 100%;
        z-index: 5;
        padding: 20px 15px;

        &:empty {
            display: none;
        }

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            overflow: auto;
            max-height: 155px;

            li {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                
                &:hover {
                    cursor: pointer;
                    font-weight: bold;
                }

                &:not(:last-child) {
                    margin-bottom: 0.5rem;
                }

                mark {
                    color: $sub-color;
                    background-color: unset;
                }
            }
        }
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        &__field {
            font-size: 2.1rem;
            padding: 5px 3.3rem;
        }

        &__search,
        &__clear {
            height: 2rem;
            width: 2rem;
        }

        &__suggestions {
            margin-top: 12px;
            padding: 1.8rem 1.6rem;

            ul {
                max-height: 280px;
                li {
                    &:not(:last-child) {
                        margin-bottom: 14px;
                    }
                }
            }
        }
    }
}

// FOOTER CONTACT
.contact {
    padding: 100px 30px;
    position: relative;
    background-image: url('../../images/contact_bg.webp');
    background-size: cover;
    background-position: center;
    text-align: center;
    color: #FFFFFF;

    &__content {
        font-size: 30px;
        line-height: 35px;
        font-weight: 900;
        text-transform: uppercase;
    }
    &__subtext {
        margin-top: 0.7rem;
        font-size: 1.12rem;
        line-height: 1.24em;
        &:empty {
            display: none;
        }
    }
    &__button {
        margin-top: 40px;
    } 

    @media (min-width: map-get($breakpoints, 'lg')) {
        padding: 7rem 80px 6.2rem 80px;

        &__content {
            font-size: 1.65rem;
            line-height: 1.2em;
            margin-left: auto;
            margin-right: auto;
        }
        &__subtext {
            margin-top: 1rem;
            font-size: 1.25rem;
            line-height: 1.1em;
        }
        &__button {
            margin-top: 2rem;
        }
    }
}

.hot-searches {
    &__title {
        font-weight: 900;
        color: #FFFFFF;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    &__container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 20px 45px;
        & > * {
            font-weight: normal;
            color: #FFFFFF;
            text-decoration: none;
        }
    }
    @media (min-width: map-get($breakpoints, 'lg')) {
        &__title {
            font-size: 1.25rem;
            margin-bottom: 2rem;
        }
        &__container {
            flex-direction: row;
            gap: 2rem 3rem;
        }
    }
}

// FILTER MENU
.btn.show-filter {
    position: relative;
    font-size: 16px;
    font-weight: normal;
    padding: 0 0 0 40px;
    border-radius: unset;
    // height: 27px;
    color: $main-color;
    background-color: transparent;
    cursor: pointer;

    &:hover {
        background: unset;
    }

    &::before {
        content: '';
        display: inline-block;
        width: 25px;
        height: 25px;
        @include vertical-center;
        left: 0;
        @include background-picto('filters.svg');
        @include filter('main');
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        display: none;
    }
}

.filter-menu {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow-y: auto;
    background-color: $background-color;

    &::before {
        content: "";
        display: block;
        position: fixed;
        height: 425px;
        width: 220px;
        right: -75px;
        bottom: -85px;
        @include background-picto('pappus_2.svg');
        background-position: bottom right;
        transform: rotate(-20deg);
        @include filter('main');
    }

    &.opened {
        display: block;
    }

    &__content {
        margin-top: 90px;
        padding: 0;
    }

    &__btn-close {
        display: block;
        width: 15px;
        height: 15px;
        position: absolute;
        cursor: pointer;
        left: 20px;
        top: 30px;
        @include background-picto('close_bold.svg');
    }

    &__buttons {
        display: grid;
        justify-content: center;
        margin-top: 75px;
        margin-bottom: 50px;

        .btn {
            z-index: 2;
            width: 230px;

            @media (min-width: map-get($breakpoints, 'lg')) {
                @include font-size(20);
                padding: 10px 25px 13px;
            }
        }
    }

    .form-list {
        padding-left: 12px;
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        display: block;
        position: relative;
        z-index: 1;
        width: 100%;
        overflow-y: unset;
        background-color: unset;

        &::before, &__btn-close, &__buttons {
            display: none;
        }

        &.opened {
            display: block;

            .btn-close, &__buttons {
                display: none;
            }
        }
        &__content {
            margin-top: 5rem;
        }
    }
}

.podcast {
    background-image: url('../../images/podcast_bg.webp');
    background-size: cover;
    background-position: center;
    padding: 50px 50px 45px;
    text-align: center;
    color: #FFFFFF;

    &__title {
        font-size: 40px;
        line-height: 40px;
        font-weight: 900;
        max-width: 450px;
        margin: 0 auto 45px;
        text-transform: uppercase;
        @include shadow('text-shadow');
    }

    &__text {
        max-width: 240px;
        margin: 0 auto 45px;
        font-size: 18px;
        line-height: 25px;

        &__highlight {
            display: block;
            font-weight: 900;
            text-transform: uppercase;
            margin-bottom: 10px;
        }

        &::before {
            content: '';
            display: block;
            width: 120px;
            height: 120px;
            margin: 0 auto 35px;
            @include background-picto('play_podcast.svg');
            @include filter('white');
        }
    }

    &__btn {
        width: 200px;
    }

    @media (min-width: map-get($breakpoints, 'xl')) {
        padding: 65px 65px 80px;

        &__title {
            font-size: 70px;
            line-height: 70px;
            margin-bottom: 80px;
            max-width: 650px;
        }

        &__content {
            display: flex;
            align-items: center;
        }

        &__text {
            max-width: 700px;
            margin: 0 auto 0 0;
            position: relative;
            padding-left: 200px;
            font-size: 30px;
            line-height: 35px;
            text-align: left;

            &__highlight {
                margin-bottom: 0;
            }

            &::before {
                position: absolute;
                left: 0;
                width: 155px;
                height: 155px;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
            }
        }

        &__btn {
            width: 280px;
        }
    }
}

.steps-carousel {
    .step-slide {
        &::before {
            content: attr(data-step);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            text-align: center;
            font-size: 100px;
            font-weight: 900;
            color: #FFFFFF;
            background-color: $main-color;
        }

        & > * {
            margin-left: 25px;
            margin-right: 25px;
        }

        &__title {
            font-size: 25px;
            line-height: 30px;
            font-weight: 900;
            text-transform: uppercase;
            margin-top: 40px;
            margin-bottom: 30px;
        }

        &__list,
        &__text {
            margin-bottom: 10px;
            @include content-text-size;
        }
        &__list {
            padding-top: 20px;
            padding-bottom: 20px;
        }
        &__button {
            margin-top: 35px;
            margin-bottom: 35px;
            text-align: center;
        }
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        .step-slide {
            display: flex;
            align-items: center;
            gap: 35px;
            padding-right: 35px;

            & > * {
                flex: 1 1 0%;
                margin-left: 0;
                margin-right: 0;
            }
            
            &::before {
                width: 150px;
                flex: 0 0 auto;
                font-size: 6rem;
                min-height: 100%;
            }

            &__title,
            &__list {
                margin-top: 0;
                margin-bottom: 0;
                &:last-child {
                    margin-bottom: 0;
                }
            }

            &__title {
                font-size: 1.65rem;
                line-height: 1.1em;
            }

            &__button {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
    // @media (min-width: map-get($breakpoints, 'xxxl')) {
    //     .step-slide {
            
    //         &::before {
    //             font-size: 150px;
    //         }

    //         &__title {
    //             font-size: 40px;
    //             line-height: 45px;
    //             margin-left: 40px;
    //         }
    //     }
    // }
}

.stats-carousel {
    .swiper-slide {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 320px;
        padding: 25px 15px;
    }
    
    &__title {
        font-size: 30px;
        line-height: 1.15em;
        text-transform: uppercase;
        text-align: center;
        font-weight: 900;
        margin-bottom: 5px;
    }
    
    &__content {
        text-align: center;
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        padding: 0.5rem 1rem;
        &__title {
            font-size: 2.9rem;
            line-height: 1.15em;
        }
        &__content {
            font-size: 1.25rem;
            line-height: 1.11em;
        }
    }
}