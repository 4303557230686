// --------- THEME FORM

// --- Global

// Hide honeypot field
[class^="hf_"] {
    display: none;
}

%form-field {
    width: 100%;
    border: none;
    border-bottom: solid 1px $main-color;
    background: none;
    appearance: none;
    color: $main-color;
    padding-bottom: 5px;

    @media (min-width: map-get($breakpoints, 'lg')) {
        border-bottom: solid 3px $main-color;
    }
}

textarea {
    resize: none;
}

// Input
.form__field {
    @extend %form-field;
    &::placeholder {
        color: $main-color;
        opacity: 1;
    }
    &:focus-visible {
        outline: none;
    }
    &.error {
        border-color: red;
    }
}

// Block
.form__block {
    position: relative;

    & > div {
        position: relative;
    }

    & + .form__block {
        margin-top: 25px;
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        & + .form__block {
            margin-top: 1.5rem;
        }
    }
}

// Required field
.floating-required {
    display: none;
    color: transparent;
    @include font-size(18);
    position: absolute;
    top: 1px;
    left: 2px;
    pointer-events: none;

    span {
        color: $main-color;
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        @include font-size(24);
    }

    // @media (min-width: map-get($breakpoints, 'xxxl')) {
    //     @include font-size(30);
    // }
}
select.form__field:invalid ~ .floating-required,
.form__field:placeholder-shown ~ .floating-required,
.custom-select.required:not(.selected) .floating-required {
    display: block;
}

.form__choice {
    width: 20px;
    height: 20px;
    vertical-align: top;
    appearance: none;
    border: solid 2px;
    @include apply-color('main', 'border-color');
    border-radius: 5px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 13px;
    display: flex;
    flex-wrap: wrap;

    & > * {
        flex-basis: 100%;
    }
    .form__choice {
        flex-shrink: 0;
    }

    &:checked {
        @include apply-color('main', 'background-color');
        background-image: url('../../images/picto/checked_white.svg');
    }
    & + label,
    & + .form__label {
        padding-left: 10px;
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        width: 20px;
        height: 20px;
        background-size: 13px;

        & > * {
            flex-basis: auto;
        }

        & + label,
        & + .form__label {
            padding-left: 10px;
        }
    }
    // @media (min-width: map-get($breakpoints, 'xxxl')) {
    //     & + label,
    //     & + .form__label {
    //         padding-left: 20px;
    //         @include font-size(30);
    //     }
    // }

}

.form-list {
    position: relative;
    display: block;

    &__choices {
        display: flex;
        gap: 25px;
        flex-direction: column;
        overflow: auto;
        z-index: 5;

        & > div,
        & > a {
            display: flex;
            align-items: center;
            white-space: nowrap;
            & > * {
                cursor: pointer;
            }
        }
    }
}

.form__choices {

    &--inline {
        &,
        .form-list__choices {
            display: flex;
            align-items: center;
            gap: 40px;
            flex-direction: row;
        }

        div {
            display: flex;
            align-items: center;
            white-space: nowrap;
        }
    }
}

.form__unique-checkbox {
    display: flex;
    margin-bottom: 50px;
    .form__choice {
        flex-shrink: 0;
        margin-top: 4px;
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        margin-bottom: 40px;
    }
}


// --- Errors
.form__errors {
    list-style-type: none;
    padding: 0;
    margin: 0;
    color: red;
    @include font-size(16);

    @media (min-width: map-get($breakpoints, 'sm')) {
        @include font-size(12);
    }
}

.notice {
    margin-top: 20px;
    margin-bottom: 45px;
    font-size: 0.75rem;

    &:last-child{
        margin-top: 10px;
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        margin-top: 40px;
        margin-bottom: 40px;
    }
}

.button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 45px;

    .btn {
        min-width: 200px;
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        justify-content: flex-start;
        .btn {
            min-width: 285px;
        }
    }
}

// Select CSS
.custom-select, .study-filters__filter, .project-filters__filter {
    position: relative;
    width: 100%;
    text-align: left;

    select {
        display: none;
    }

    .select-selected {
        @extend %form-field;
        position: relative;
        cursor: pointer;
        padding-bottom: 10px;

        &::after {
            content: "";
            position: absolute;
            @include background-picto('a_bottom_bold.svg');
            width: 13px;
            height: 13px;
            @include filter('main');
            @include vertical-center;
            right: 5px;
        }
    }

    .select-items {
        position: absolute;
        background-color: $background-color;
        top: 45px;
        left: 0;
        right: 0;
        z-index: 9;
        border-radius: 0;
        border-top: none;
        border-radius: 0 0 5px 5px;
        max-height: 200px;
        overflow: auto;

        &.select-hide {
            display: none;
        }

        div {
            cursor: pointer;
            color: $main-color;
            min-height: 30px;
            margin-left: 15px;
            margin-right: 15px;
            margin-top: 5px;
            padding-left: 15px;
            padding-top: 5px;
            padding-bottom: 5px;

            &:first-child {
                margin-top: 10px;
            }
            &:last-child{
                margin-bottom: 20px;
            }

            &.same-as-selected, &:hover {
                @include text-bordered;
                & {padding-left: 20px;}
                
                &::before {
                    left: 7px;
                    width: 4px;
                    height: 70%;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .counter {
                color: #FFFFFF;
                background-color: $sub-color;
                border-radius: 50%;
                display: inline-block;
                text-align: center;
                font-weight: normal;
                font-size: 0.5em;
                height: 1.8em;
                width: 1.8em;
                line-height: 1.8em;
                vertical-align: middle;
                margin-top: -0.5em;
            }
        }
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        .select-items {
            width: 650px;
            top: 50px;

            div {
                @include font-size(20);

                &.same-as-selected, &:hover {
                    padding-left: 30px;

                    &::before {
                        width: 8px;
                    }
                }
            }
        }
    }
    // @media (min-width: map-get($breakpoints, 'xxxl')) {
    //     .select-items {
    //         top: 75px;
    //         div {
    //             @include font-size(30);
    //         }
    //     }
    // }
}