.footer {
    position: relative;
    padding: 65px 30px 115px;
    border-top: 4px solid $sub-color;
    background-color: $main-color;
    color: #FFFFFF;
    overflow: hidden;

    &::before {
        content: "";
        pointer-events: none;
        display: block;
        position: absolute;
        height: 575px;
        width: 310px;
        right: -186px;
        bottom: 425px;
        @include background-picto('pappus_2.svg');
        background-position: bottom right;
        transform: rotate(-30deg);
        @include filter('white');
    }

    &__logo {
        display: inline-block;
        width: 200px;
        margin-bottom: 50px;

        img {
            width: auto;
        }
    }

    &__menu {
        .menu__section {
            margin-bottom: 40px;

            &:nth-child(n + 5) {
                margin-top: 65px;
            }

            &--socials {
                @extend .menu__section;
                font-size: 0;

                .socials__item {
                    display: inline-block;
                    margin-right: 40px;

                    .icon {
                        display: inline-block;
                        width: 25px;
                        height: 25px;
                        background-repeat: no-repeat;
                        background-position: center;
                        @include filter('white');
                    }

                    &:hover .icon {
                        @include filter('sub');
                    }

                    &--linkedin {
                        @extend .socials__item;
                        .icon {
                            background-image: url('../../images/logo/linkedin.svg');
                        }
                    }

                    &--x {
                        @extend .socials__item;
                        .icon {
                            background-image: url('../../images/logo/x.svg');
                        }
                    }
                }
            }
        }

        .section__title {
            font-size: 28px;
            font-weight: 700;
            margin-bottom: 15px;
        }

        .section__links {
            @include list-reset;

            .links__item {
                margin-bottom: 10px;
                a {
                    @include link-reset;
                    cursor: pointer;
                    white-space: nowrap;

                    &::after {
                        content: "";
                        display: inline-block;
                        width: 0.7em;
                        height: 0.7em;
                        @include background-picto('a_right.svg');
                        @include filter('white');
                        margin-left: 9px;
                        vertical-align: middle;
                    }

                    &:hover {
                        color: $sub-color;

                        &::after {
                            @include filter('sub');
                        }
                    }
                }
            }
        }
    }

    &__footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 55px -20px 35px;

        .footer__item {
            margin-bottom: 20px;

            &:nth-child(2)::before,
            &:nth-child(5)::before {
                content: "";
                display: inline-block;
                background-color: #FFFFFF;
                width: 1px;
                height: 18px;
                margin: 0 20px;
                vertical-align: middle;
            }

            a {
                @include link-reset;

                &:hover {
                    color: $sub-color;
                }
            }
        }
    }

    &__copyright {
        @include font-size(10);
        text-align: center;
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        
        padding: 50px 7.5521vw;
        border-top-width: 9px;

        &_top {
            display: flex;
        }

        &::before {
            width: 310px;
            bottom: -150px;
            left: 71%;
            transform: rotate(0);

            @media (max-height: map-get($breakpoints-height, 'md')) {
                bottom: -175px;
            }
        }

        &__logo {
            vertical-align: top;
            margin-right: calc((160 / 1920) * 100vw);
            flex: 0 0 auto;
        }

        &__menu {
            margin-top: 2.5rem;
            display: inline-grid;
            grid-auto-flow: column;
            grid-template-rows: repeat(2, 1fr);
            grid-auto-columns: minmax(150px, auto);
            gap: 2rem 6.25rem;

            .menu__section {
                margin: 0 !important;
                &--socials {
                    display: none;
                }

                // Modify element placement in grid to match design
                &:nth-child(2) {
                    grid-column: 2;
                }
            }

            .section__title {
                font-size: 1.15rem;
                margin-bottom: 0.5rem;
            }

            .section__links .links__item {
                margin-bottom: 0.2rem;
                a {
                    font-size: 0.74rem;
                    &::after {
                        margin-left: 11px;
                    }
                }
            }
        }

        &__footer,
        &__copyright {
            flex-basis: 100%;
        }

        &__footer {
            margin: 50px 0 40px;
            
            .footer__item {
                margin-bottom: 0;

                a {
                    font-size: 0.74rem;
                }
                &:nth-child(n + 2)::before {
                    content: "";
                    display: inline-block;
                    background-color: #FFFFFF;
                    width: 1px;
                    height: 18px;
                    margin: 0 35px;
                    vertical-align: middle;
                }
            }
        }
    }
    // @media (min-width: map-get($breakpoints, 'xl')) {
    //     padding: 50px 7.5521vw;
    // }
    // @media (min-width: map-get($breakpoints, 'xxxl')) {
    //     &__logo {
    //         width: 260px;
    //     }
    //     .section__title {
    //         @include font-size(28);
    //     }
    //     .section__links {
    //         a {
    //             @include font-size(18);
    //         }
    //     }
    //     &__footer {
    //         .footer__item {
    //             a {
    //                 @include font-size(18);
    //             }
    //         }
    //     }
    //     &::before {
    //         height: 625px;
    //     }  
    // }
}