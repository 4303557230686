// --------- COMPONENTS

@import '_mixins.scss';

.title {
    line-height: 1.2;

    sup {
        font-size: 0.5em;
        top: -0.75em;
    }

    &--s1 {
        @extend .title;
        font-size: 40px;
        text-transform: uppercase;
        font-weight: 900;

        &--sub {
            @extend .title--s1;
            text-transform: none;
        }
    }
    
    &--s2 {
        @extend .title;
        font-size: 35px;
        font-weight: 900;
        text-transform: uppercase;

        &--white {
            @extend .title--s2;
            color: #FFFFFF;
            @include shadow('text-shadow')
        }
    }

    &--s3 {
        @extend .title;
        font-size: 45px;
        line-height: 50px;
        text-transform: uppercase;
        font-weight: 100;
        text-align: center;
    }

    &--s4 {
        @extend .title;
        font-size: 30px;
        line-height: 1em;
        font-weight: 900;
        text-transform: uppercase;
        word-break: break-word;
        hyphens: auto;
    }

    &--s5 {
        @extend .title;
        font-size: 25px;
        font-weight: 900;
        text-transform: uppercase;

        &--sub {
            @extend .title--s5;
            text-transform: none;
        }
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        &--s1 {
            font-size: 2.48rem;
            line-height: 1.15em;
        }
        
        &--s2 {
            font-size: 2.92rem;
            line-height: 1em;
        }
    
        &--s3 {
            font-size: 3.75rem;
            line-height: 1.07em;
        }

        &--s4, &--s5 {
            font-size: 2.5rem;
            line-height: 1.15em;
        }
    }
}

.container {
    width: 100%;
    max-width: 1000px;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: map-get($breakpoints, 'lg')) {
        padding-right: 150px;
        padding-left: 150px;
        max-width: 1350px;
    }
    &--lg {
        @extend .container;
        @media (min-width: map-get($breakpoints, 'lg')) {
            padding-right: 75px;
            padding-left: 75px;
        }
    }
}

.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px 25px 13px;
    border-radius: 5px;
    background-color: $sub-color;
    height: 70px;
    flex-shrink: 0;
    @include link-reset;
    color: #FFFFFF;
    font-weight: 900;
    font-size: 1rem;

    &:hover {
        background-color: $purple-dark-color;
    }

    // @media (min-width: map-get($breakpoints, 'xxxl')) and (min-height: 801px) {
    //     @include font-size(28);
    //     line-height: 25px;
    //     height: 84px;
    //     padding: 10px 40px 13px;
    // }
}

.dots-separator {
    padding: 2rem 0;
    @include filter('main');

    &::before {
        content: "";
        display: block;
        width: 10px;
        height: 60px;
        @include background-picto('three_dots.svg');
        margin: auto;
    }

    // @media (min-width: map-get($breakpoints, 'xxxl')) and (min-height: 801px) {
    //     &::before {
    //         width: 15px;
    //         height: 70px;
    //     }
    // }
}

.text-bordered {
    @include text-bordered;
}

.highlight-content {
    
    font-size: 1.12rem;
    line-height: 1.24em;

    @include text-bordered;

    &--bold {
        @extend .highlight-content;
        font-weight: 700;
    }

    &__title {
        display: block;
        font-size: 22px;
        line-height: 25px;
        font-weight: 900;
        margin-bottom: 10px;
    }
    @media (min-width: map-get($breakpoints, 'lg')) {
        
        font-size: 1.25rem;

        &__title {
            font-size: 1.66rem;
            line-height: 1.2em;
            margin-bottom: 0.9rem;
        }
    }
}

.list--dots {
    padding-left: 20px;

    li::marker {
        color: $sub-color;
    }
}

.carousel,
.default-carousel-style {
    position: relative;

    .swiper-slide {
        height: auto;
        @include rounded-border;
    }
}

.container .carousel,
.container .default-carousel-style {
    margin-right: -20px;

    @media (min-width: map-get($breakpoints, 'lg')) {
        margin-right: 0;
    }
}

.paginate {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    gap: 15px;

    .page {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25px;
        width: 25px;
        color: $main-color;
        text-decoration: none;
        font-weight: normal;

        &.active, &:hover {
            background: $main-color;
            color: #FFFFFF;
        }
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        gap: 25px;
        margin-top: 6rem;
        .page {
            height: 34px;
            width: 34px;
        }
    }

    // @media (min-width: map-get($breakpoints, 'xxxl')) {
    //     gap: 30px;
    //     margin-top: 140px;
    //     .page {
    //         font-size: 30px;
    //         line-height: 40px;
    //         height: 40px;
    //         width: 40px;
    //     }
    // }
}