// --------- BASE

*,
*::before,
*::after {
    box-sizing: border-box;
}

:root {
    scroll-behavior: smooth;
}

html {
    font-size: 18px;
    line-height: 1.389;

    @media (min-width: map-get($breakpoints, 'lg')) {
        font-size: 15px;
    }
    @media (min-width: map-get($breakpoints, 'xl')) {
        font-size: 20px;

        @media (max-height: map-get($breakpoints-height, 'md')) {
            font-size: 17px;
        }
    }
}

body {
    @include font-segoeui;
    background-color: $background-color;
    color: $main-color;

    &.no-scroll {
        overflow: hidden;
    }
}

button {
    background-color: transparent;
    cursor: pointer;
    border: none;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

img {
    max-width: 100%;
    width: auto;
    height: auto;
}

a {
    font-weight: 700;
    color: $sub-color;
}

.veil {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    cursor: pointer;

    &.visible {
        display: block;
    }
}